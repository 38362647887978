.ck-editor__editable_inline {
  min-height: 150px;
}

.custom-editor.max-height .ck-editor__editable_inline {
  max-height: 500px;
}

.ck-editor i {
  font-size: unset !important;
  color: unset !important;
}

.editor-disabled .ck-sticky-panel {
  display: none;
}

label {
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.70) !important;
}